import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const ArticleEnglish = () => {
  const [articleData, setArticleData] = useState({
    headline: '',
    content: '',
    tags: [],
    youtubeLink: '',
    category: '',
    photos: null,
  });

  const [isSubmissionSuccess, setIsSubmissionSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setArticleData({
      ...articleData,
      [name]: value,
    });
  };

  const handleTagChange = (selectedOptions) => {
    const tags = selectedOptions.map((option) => option.value);
    setArticleData({
      ...articleData,
      tags,
    });
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    console.log('Selected file:', file);
    setArticleData({
      ...articleData,
      photos: file,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('headline', articleData.headline);
    formData.append('content', articleData.content);
    formData.append('tags', articleData.tags.join(','));
    formData.append('youtubeLink', articleData.youtubeLink);
    formData.append('category', articleData.category);
    formData.append('photos', articleData.photos || '');

    console.log('FormData:', formData);

    setIsLoading(true);

    try {
      const response = await axios.post('https://api.theeverestnews.com/api/english/create', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('Article created successfully:', response.data);
      setIsSubmissionSuccess(true);
      setArticleData({
        headline: '',
        content: '',
        tags: [],
        youtubeLink: '',
        category: '',
        photos: null,
      });
    } catch (error) {
      console.error('Error creating article:', error);
      setError('Error creating article. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formHeadline">
              <Form.Label>Headline</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter headline"
                name="headline"
                value={articleData.headline}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formContent">
           <Form.Label>Content</Form.Label>
           <ReactQuill
            theme="snow"
            value={articleData.content} 
            onChange={(value) => setArticleData({ ...articleData, content: value })}
            modules={{
              toolbar: [
                [{ 'header': [1, 2, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'color': [] }],
                ['image'],
                ['link'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                ['clean'],
              ],
            }}
            required

           

          />
       </Form.Group>

            <Form.Group controlId="formTags">
              <Form.Label>Tags</Form.Label>
              <CreatableSelect
                isMulti
                onChange={handleTagChange}
                options={articleData.tags.map((tag) => ({ value: tag, label: tag }))}
              />
            </Form.Group>

            <Form.Group controlId="formYouTubeLink">
              <Form.Label>YouTube Link</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter YouTube link"
                name="youtubeLink"
                value={articleData.youtubeLink}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="formCategory">
              <Form.Label>Category</Form.Label>
              <Select
                options={['politics', 'sports', 'economics', 'lifestyle', 'travel', 'international', 'science', 'society'].map((category) => ({
                  value: category,
                  label: category.charAt(0).toUpperCase() + category.slice(1),
                }))}
                onChange={(selectedOption) => setArticleData({ ...articleData, category: selectedOption.value })}
              />
            </Form.Group>

            <Form.Group controlId="formImage">
              <Form.Label>Photo</Form.Label>
              <Form.Control
                type="file"
                accept="photos/*"
                onChange={handlePhotoChange}
                required
              />
            </Form.Group>

            <Button variant="primary" type="submit" disabled={isLoading}>
              {isLoading ? 'Submitting...' : 'Submit'}
            </Button>

            {isSubmissionSuccess && (
              <div className="mt-3 text-success">
                Article submitted successfully!
              </div>
            )}

            {error && (
              <div className="mt-3 text-danger">
                {error}
              </div>
            )}
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ArticleEnglish;
