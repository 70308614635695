import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Button, Modal, Form } from 'react-bootstrap';

const AuthorList = () => {
  const [authors, setAuthors] = useState([]);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedAuthor, setSelectedAuthor] = useState(null);
  const [updateFormData, setUpdateFormData] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    authorPhoto: null,
  });

  useEffect(() => {
    fetchAuthors();
  }, []);

  const fetchAuthors = async () => {
    try {
      const response = await axios.get('https://api.theeverestnews.com/api/authors');
      setAuthors(response.data.authors);
    } catch (error) {
      console.error('Error fetching authors:', error);
    }
  };

  const handleUpdate = (author) => {
    setSelectedAuthor(author);
    setUpdateFormData({
      firstName: author.firstName,
      middleName: author.middleName || '',
      lastName: author.lastName,
      authorPhoto: null,
    });
    setShowUpdateModal(true);
  };

  const handleDelete = (author) => {
    setSelectedAuthor(author);
    setShowDeleteModal(true);
  };

  const handleUpdateSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append('firstName', updateFormData.firstName);
      formData.append('middleName', updateFormData.middleName);
      formData.append('lastName', updateFormData.lastName);
      formData.append('authorPhoto', updateFormData.authorPhoto);

      await axios.put(`https://api.theeverestnews.com/api/authors/${selectedAuthor._id}`, formData);

      // Clear the form data after successful update
      setUpdateFormData({
        firstName: '',
        middleName: '',
        lastName: '',
        authorPhoto: null,
      });

      fetchAuthors();
      setShowUpdateModal(false);
    } catch (error) {
      console.error('Error updating author:', error);
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      await axios.delete(`https://api.theeverestnews.com/api/authors/${selectedAuthor._id}`);

      fetchAuthors();
      setShowDeleteModal(false);
    } catch (error) {
      console.error('Error deleting author:', error);
    }
  };

  const handleFileChange = (e) => {
    setUpdateFormData({ ...updateFormData, authorPhoto: e.target.files[0] });
  };

  return (
    <>
      <div className="mb-3">
        <h2>Author List</h2>
      </div>

      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
        {authors.map((author) => (
          <div key={author._id} className="col">
            <Card style={{ width: '18rem' }}>
              <div className="circle-photo">
                <Card.Img
                  variant="top"
                  src={`https://api.theeverestnews.com/uploads/authors/${author.photo}`}
                  className="rounded-circle"
                />
              </div>
              <Card.Body>
                <Card.Title>Author Details</Card.Title>
                <Card.Text>
                  <strong>First Name:</strong> {author.firstName}
                </Card.Text>
                {author.middleName && (
                  <Card.Text>
                    <strong>Middle Name:</strong> {author.middleName}
                  </Card.Text>
                )}
                <Card.Text>
                  <strong>Last Name:</strong> {author.lastName}
                </Card.Text>
                <Button variant="primary" size="sm" onClick={() => handleUpdate(author)}>
                  Update
                </Button>{' '}
                
                {/* delete the author */}
                {/* <Button variant="danger" size="sm" onClick={() => handleDelete(author)}>
                  Delete
                </Button> */}

              </Card.Body>
            </Card>
          </div>
        ))}
      </div>

      {/* Update Author Modal */}
      <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Author</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formFirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter first name"
                value={updateFormData.firstName}
                onChange={(e) => setUpdateFormData({ ...updateFormData, firstName: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="formMiddleName">
              <Form.Label>Middle Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter middle name"
                value={updateFormData.middleName}
                onChange={(e) => setUpdateFormData({ ...updateFormData, middleName: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="formLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter last name"
                value={updateFormData.lastName}
                onChange={(e) => setUpdateFormData({ ...updateFormData, lastName: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="formAuthorPhoto">
              <Form.Label>Author Photo</Form.Label>
              <Form.Control type="file" accept="image/*" onChange={handleFileChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowUpdateModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdateSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this author?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirm}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AuthorList;
