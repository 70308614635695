// TagList.js
import React, { useState, useEffect } from 'react';
import { Container, Badge, Button, Modal, Form, Alert } from 'react-bootstrap';
import axios from 'axios';

const TagList = () => {
  const [tags, setTags] = useState([]);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const [updateFormData, setUpdateFormData] = useState('');
  const [addFormData, setAddFormData] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    fetchTags();
  }, []);

  const fetchTags = async () => {
    try {
      const response = await axios.get('https://api.theeverestnews.com/api/tags');
      setTags(response.data.data);
    } catch (error) {
      console.error('Error fetching tags:', error);
      setErrorMessage('Failed to fetch tags. Please try again.');
      setTimeout(() => {
        setErrorMessage('');
      }, 1000); // Clear the error message after 5 seconds
    }
  };

  const handleUpdate = (tag) => {
    setSelectedTag(tag._id);
    setUpdateFormData(tag.name.substring(1)); // Remove "#" before displaying in the modal
    setShowUpdateModal(true);
  };

  const handleUpdateSubmit = async () => {
    try {
      await axios.put(`https://api.theeverestnews.com/api/tags/${selectedTag}`, {
        name: `#${updateFormData}`, // Add "#" prefix before updating
      });
      fetchTags();
      setShowUpdateModal(false);
    } catch (error) {
      console.error('Error updating tag:', error);
      setErrorMessage('Failed to update tag. Please try again.');
      setTimeout(() => {
        setErrorMessage('');
      }, 5000); // Clear the error message after 5 seconds
    }
  };

  const handleAddSubmit = async () => {
    try {
      await axios.post('https://api.theeverestnews.com/api/tags', { name: `#${addFormData}` }); // Add "#" prefix before adding
      fetchTags();
      setShowAddModal(false);
      setAddFormData('');
    } catch (error) {
      console.error('Error adding tag:', error);
      setErrorMessage('Failed to add tag. Please try again.');
      setTimeout(() => {
        setErrorMessage('');
      }, 5000); // Clear the error message after 5 seconds
    }
  };

  const handleDelete = (tag) => {
    setSelectedTag(tag._id);
    setShowDeleteModal(true);
  };

  const handleDeleteSubmit = async () => {
    try {
      await axios.delete(`https://api.theeverestnews.com/api/${selectedTag}`);
      fetchTags();
      setShowDeleteModal(false);
    } catch (error) {
      console.error('Error deleting tag:', error);
      setErrorMessage('Failed to delete tag. Please try again.');
      setTimeout(() => {
        setErrorMessage('');
      }, 5000); // Clear the error message after 5 seconds
    }
  };

  return (
    <Container className="tags-list-container mt-3">
      {/* Display error message */}
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

      <h2>Tags List</h2>

      {tags.map((tag) => (
        <Badge key={tag._id} variant="secondary" className="mr-2" style={{ cursor: 'pointer' }}>
          {tag.name}
          <Button variant="info" size="sm" className="ml-2" onClick={() => handleUpdate(tag)}>
            Update
          </Button>
          
          {/* This is to delete the tag */}
          {/* <Button variant="danger" size="sm" onClick={() => handleDelete(tag)}>
            Delete
          </Button> */}
        </Badge>
      ))}

      {/* Update Tag Modal */}
      <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Tag</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="updateTag">
              <Form.Label>Tag Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter tag name"
                value={updateFormData}
                onChange={(e) => setUpdateFormData(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowUpdateModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdateSubmit}>
            Update Tag
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Add Tag Modal */}
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Tag</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="addTag">
              <Form.Label>Tag Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter tag name"
                value={addFormData}
                onChange={(e) => setAddFormData(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAddModal(false)}>
            Close
          </Button>
          <Button variant="success" onClick={handleAddSubmit}>
            Add Tag
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Tag Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Tag</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete the tag "{selectedTag && selectedTag.name}"?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteSubmit}>
            Delete Tag
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Button to Add Tag */}
      <Button variant="success" onClick={() => setShowAddModal(true)}>
        Add Tag
      </Button>
    </Container>
  );
};

export default TagList;
