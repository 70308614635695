import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AdminList = () => {
  const [adminList, setAdminList] = useState([]);

  useEffect(() => {
    fetchAdmins();
    const interval = setInterval(assignRandomStatus, 1000); // Run every second
    return () => clearInterval(interval);
  }, []);

  const fetchAdmins = async () => {
    try {
      const response = await axios.get('https://api.theeverestnews.com/api/auth/admins');
      setAdminList(response.data.admins);
    } catch (error) {
      console.error('Error fetching admins:', error);
    }
  };

  const assignRandomStatus = () => {
    setAdminList((prevAdminList) =>
      prevAdminList.map((admin) => ({
        ...admin,
        online: Math.random() > 0.5, // Randomly assign online status
        lastOnline: getRandomTime(), // Generate random time
      }))
    );
  };

  const getRandomTime = () => {
    const now = new Date();
    const randomOffset = Math.floor(Math.random() * 3600); // Random time within 1 hour (3600 seconds)
    return new Date(now.getTime() - randomOffset * 1000); // Convert to milliseconds
  };
  const calculateTimeDifference = (lastOnline) => {
    if (!lastOnline) return 'Online';
  
    const now = new Date();
    const diffInMilliseconds = now - lastOnline;
  
    const hours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
    const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diffInMilliseconds % (1000 * 60)) / 1000);
  
    // Return formatted time difference
    if (hours > 0) {
      return `${hours} hrs`;
    } else if (minutes > 0) {
      return `${minutes} min`;
    } else {
      return `${seconds} sec`;
    }
  };
  

  return (
    <div>
      {adminList.map((admin) => (
        <div key={admin._id}>
          <span style={{ fontSize: '24px', color: admin.online ? 'green' : 'red' }}>● </span>
          {admin.username} - {admin.online ? 'Online' : 'Offline'}
          {admin.lastOnline && ` (${calculateTimeDifference(admin.lastOnline)})`}
        </div>
      ))}
    </div>
  );
};

export default AdminList;
