import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, InputGroup, Dropdown, DropdownButton, Badge } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const NewsSchedule = () => {
  const [category, setCategory] = useState('');
  const [headline, setHeadline] = useState('');
  const [subheadline, setSubheadline] = useState('');
  const [content, setContent] = useState('');
  const [photos, setPhotos] = useState([]);
  const [photo, setPhoto] = useState('');
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [publicationDate, setPublicationDate] = useState('');
  const [scheduledNews, setScheduledNews] = useState([]);
  const [scheduleTimer, setScheduleTimer] = useState(null);

  const handleTagChange = (e) => {
    const value = e.target.value;
    if (value.trim() === '') return; // Ignore empty tags
    // Add the tag only if it starts with a #
    if (value.startsWith('#') && !tags.includes(value)) {
      setTags([...tags, value]);
    }
  };

  const handleTagSelect = (tag) => {
    const updatedSelectedTags = selectedTags.includes(tag)
      ? selectedTags.filter((selectedTag) => selectedTag !== tag)
      : [...selectedTags, tag];

    setSelectedTags(updatedSelectedTags);
  };

  const handlePhotoChange = (e) => {
    setPhoto(e.target.value);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPhotos([...photos, reader.result]);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCategorySelect = (selectedCategory) => {
    setCategory(selectedCategory);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic, e.g., sending data to a server
    // For simplicity, in this example, we'll just log the data and reset the form
    const submittedData = {
      category,
      headline,
      subheadline,
      content,
      photos,
      tags: selectedTags,
      publicationDate,
    };

    // Update the scheduled news state
    setScheduledNews([...scheduledNews, submittedData]);

    // Reset the form
    setCategory('');
    setHeadline('');
    setSubheadline('');
    setContent('');
    setPhotos([]);
    setPhoto('');
    setTags([]);
    setSelectedTags([]);
    setPublicationDate('');
  };

  useEffect(() => {
    // Set up a timer to check for scheduled news every minute
    const timer = setInterval(checkScheduledNews, 60000);

    // Clear the timer when the component is unmounted
    return () => {
      clearInterval(timer);
    };
  }, [scheduledNews]);

  const checkScheduledNews = () => {
    const currentTime = new Date();

    // Check if there are any scheduled news to be posted
    const newsToPost = scheduledNews.filter((item) => {
      const scheduleTime = new Date(item.publicationDate);
      return currentTime >= scheduleTime;
    });

    if (newsToPost.length > 0) {
      // Perform the action to post the news (e.g., send to server)
      console.log('Posting scheduled news:', newsToPost);

      // Remove the posted news from the scheduled list
      setScheduledNews(scheduledNews.filter((item) => !newsToPost.includes(item)));
    }
  };

  const scheduleNews = () => {
    const selectedDateTime = new Date(publicationDate);
    const currentDateTime = new Date();

    // Check if the selected publication date and time are in the future
    if (selectedDateTime > currentDateTime) {
      // Clear any existing timer
      if (scheduleTimer) {
        clearTimeout(scheduleTimer);
      }

      // Calculate the time difference between now and the selected publication date and time
      const timeDifference = selectedDateTime - currentDateTime;

      // Schedule the news for the specified time
      const timer = setTimeout(() => {
        // Perform the action to post the news (e.g., send to server)
        console.log('Posting scheduled news:', [scheduledNews[scheduledNews.length - 1]]);
      }, timeDifference);

      // Set the new timer
      setScheduleTimer(timer);
    } else {
      // Display an alert if the selected date and time are not in the future
      alert('Please choose a future date and time for scheduling.');
    }
  };

  return (
    <Container className="news-form-container mt-3">
      <h2>Schedule News</h2>
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="category">
              <Form.Label>Category</Form.Label>
              <DropdownButton title={category || 'Select Category'} onSelect={handleCategorySelect}>
                <Dropdown.Item eventKey="technology">Technology</Dropdown.Item>
                <Dropdown.Item eventKey="business">Business</Dropdown.Item>
                <Dropdown.Item eventKey="sports">Sports</Dropdown.Item>
                {/* Add more categories as needed */}
              </DropdownButton>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="headline">
              <Form.Label>Headline</Form.Label>
              <Form.Control type="text" value={headline} onChange={(e) => setHeadline(e.target.value)} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="subheadline">
              <Form.Label>Subheadline</Form.Label>
              <Form.Control type="text" value={subheadline} onChange={(e) => setSubheadline(e.target.value)} />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="content">
              <Form.Label>Content</Form.Label>
              <Form.Control as="textarea" rows={3} value={content} onChange={(e) => setContent(e.target.value)} />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="photo">
              <Form.Label>Upload Photo</Form.Label>
              <Form.Control type="file" accept="image/*" onChange={handleFileChange} />
              {photos.map((photoUrl, index) => (
                <div key={index} className="mb-2">
                  <img src={photoUrl} alt={`Photo ${index + 1}`} width="100" height="auto" className="mr-2" />
                  <span>{photoUrl}</span>
                </div>
              ))}
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="tags">
              <Form.Label>Tags</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text>#</InputGroup.Text>
                <Form.Control type="text" placeholder="Add tags" onChange={handleTagChange} />
              </InputGroup>
              <div className="tag-list">
                {tags.map((tag) => (
                  <Badge
                    key={tag}
                    variant={selectedTags.includes(tag) ? 'primary' : 'secondary'}
                    className="mr-2"
                    onClick={() => handleTagSelect(tag)}
                    style={{ cursor: 'pointer' }}
                  >
                    {tag}
                  </Badge>
                ))}
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="publicationDate" className="mb-3">
              <Form.Label>Publication Date</Form.Label>
              <Form.Control
                type="datetime-local"
                value={publicationDate}
                onChange={(e) => setPublicationDate(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Button variant="primary" type="button" onClick={scheduleNews}>
          Schedule News
        </Button>
      </Form>

      {/* Display scheduled news */}
      <div>
        <h2>Scheduled News</h2>
        {scheduledNews.map((item, index) => (
          <div key={index} className="mb-3">
            <h3>{item.headline}</h3>
            <p>{item.content}</p>
            <p>Category: {item.category}</p>
            <p>Tags: {item.tags.join(', ')}</p>
            <p>Publication Date: {item.publicationDate}</p>
            <div>
              {item.photos.map((photoUrl, idx) => (
                <img key={idx} src={photoUrl} alt={`Photo ${idx + 1}`} width="100" height="auto" className="mr-2" />
              ))}
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default NewsSchedule;
