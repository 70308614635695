// Security.js

import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Table, Button, Modal } from 'react-bootstrap';

const Security = () => {
  const [threats, setThreats] = useState([]);
  const [blockedDevices, setBlockedDevices] = useState([]);
  const [deviceDetails, setDeviceDetails] = useState(null);
  const [showUnblockModal, setShowUnblockModal] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);

  // Function to fetch detailed device information based on IP address
  const fetchDeviceDetails = async (ipAddress) => {
    try {
      // Replace 'YOUR_API_KEY' with your actual API key from IPinfo
      const apiKey = 'YOUR_API_KEY';
      const response = await fetch(`https://ipinfo.io/${ipAddress}/json?token=${apiKey}`);
      const data = await response.json();
      setDeviceDetails(data);
    } catch (error) {
      console.error('Error fetching device details:', error);
    }
  };

  // Function to fetch security threats and blocked devices
  const fetchData = () => {
    // Simulate fetching data (replace with actual API calls)
    const sampleThreats = [
      { id: 1, ipAddress: '192.168.1.1' },
      { id: 2, ipAddress: '192.168.1.2' },
      // ... (additional threat data)
    ];

    const sampleBlockedDevices = [
      { id: 101, ipAddress: '192.168.1.3' },
      { id: 102, ipAddress: '192.168.1.4' },
      // ... (additional blocked device data)
    ];

    setThreats(sampleThreats);
    setBlockedDevices(sampleBlockedDevices);
  };

  const handleBlockDevice = (threatId, ipAddress) => {
    // Simulate blocking the device
    console.log(`Blocking device with threat ID ${threatId}`);
    // In a real-world scenario, you'd send a request to your backend to block the device
    // Update the blocked devices list
    const blockedDevice = { id: threatId + 100, ipAddress };
    setBlockedDevices((prevBlockedDevices) => [...prevBlockedDevices, blockedDevice]);

    // Fetch device details when blocking
    fetchDeviceDetails(ipAddress);
  };

  const handleUnblockDevice = () => {
    // Simulate unblocking the device
    console.log(`Unblocking device with IP Address: ${selectedDevice?.ipAddress}`);
    // In a real-world scenario, you'd send a request to your backend to unblock the device
    // Update the blocked devices list
    setBlockedDevices((prevBlockedDevices) =>
      prevBlockedDevices.filter((device) => device.ipAddress !== selectedDevice?.ipAddress)
    );
    // Close the modal
    handleCloseUnblockModal();
  };

  const handleCloseUnblockModal = () => {
    setShowUnblockModal(false);
    setSelectedDevice(null);
  };

  const handleShowUnblockModal = (device) => {
    setSelectedDevice(device);
    setShowUnblockModal(true);
  };

  useEffect(() => {
    // Fetch security threats and blocked devices when the component mounts
    fetchData();
  }, []);

  return (
    <Container className="mt-3">
      <h2>Security Dashboard</h2>

      <Row className="mt-4">
        <Col>
          <h3>Security Threats</h3>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Threat ID</th>
                <th>IP Address</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {threats.map((threat) => (
                <tr key={threat.id}>
                  <td>{threat.id}</td>
                  <td>{threat.ipAddress}</td>
                  <td>
                    <Button
                      variant="danger"
                      onClick={() => handleBlockDevice(threat.id, threat.ipAddress)}
                    >
                      Block Device
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>

        <Col>
          <h3>Blocked Devices</h3>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Device ID</th>
                <th>IP Address</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {blockedDevices.map((device) => (
                <tr key={device.id}>
                  <td>{device.id}</td>
                  <td>{device.ipAddress}</td>
                  <td>
                    <Button
                      variant="success"
                      onClick={() => handleShowUnblockModal(device)}
                    >
                      Unblock Device
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <Modal show={showUnblockModal} onHide={handleCloseUnblockModal}>
        <Modal.Header closeButton>
          <Modal.Title>Unblock Device</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to unblock the device with IP Address: {selectedDevice?.ipAddress}?
          {deviceDetails && (
            <div>
              <h5>Device Details:</h5>
              <p>City: {deviceDetails.city}</p>
              <p>Region: {deviceDetails.region}</p>
              <p>Country: {deviceDetails.country}</p>
              <p>Location: {deviceDetails.loc}</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUnblockModal}>
            Cancel
          </Button>
          <Button variant="success" onClick={handleUnblockDevice}>
            Unblock
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Security;
