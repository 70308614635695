import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const VideoList = () => {
  const [videos, setVideos] = useState([]);
  const [editingVideo, setEditingVideo] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletingVideo, setDeletingVideo] = useState(null);

  useEffect(() => {
    fetchVideos();
  }, []);

  const fetchVideos = async () => {
    try {
      const response = await axios.get('https://api.theeverestnews.com/api/videos');
      setVideos(response.data.videos);
    } catch (error) {
      console.error('Error fetching videos:', error);
      toast.error('Failed to fetch videos');
    }
  };

  const handleEdit = (video) => {
    setEditingVideo(video);
  };

  const handleUpdate = async (updatedVideo) => {
    try {
      const formData = new FormData();
      formData.append('title', updatedVideo.title);
      formData.append('videoType', updatedVideo.videoType);

      if (updatedVideo.videoType === 'local') {
        formData.append('localFile', updatedVideo.localFile);
      } else {
        formData.append('youtubeLink', updatedVideo.youtubeLink);
      }

      const response = await axios.put(
        `https://api.theeverestnews.com/api/videos/${updatedVideo._id}`,
        formData
      );

      setEditingVideo(null);
      fetchVideos();
      toast.success(response.data.message);
    } catch (error) {
      console.error('Error updating video:', error);
      toast.error('Failed to update video');
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`https://api.theeverestnews.com/api/videos/${deletingVideo._id}`);
      fetchVideos();
      toast.success('Video deleted successfully');
    } catch (error) {
      console.error('Error deleting video:', error);
      toast.error('Failed to delete video');
    } finally {
      setDeletingVideo(null);
      setShowDeleteModal(false);
    }
  };

  const showDeleteConfirmation = (video) => {
    setDeletingVideo(video);
    setShowDeleteModal(true);
  };

  return (
    <div className="container mt-5">
      <h2>Video List</h2>
      <table className="table">
        <thead>
          <tr>
            <th>Title</th>
            <th>Video Type</th>
            <th>Video Link</th>
            <th>Video File</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {videos.map((video) => (
            <tr key={video._id}>
              <td>{video.title}</td>
              <td>{video.videoType}</td>
              <td>{video.videoType === 'local' ? 'N/A' : video.youtubeLink}</td>
              <td>
                {video.videoType === 'local' ? (
                  <a href={`https://api.theeverestnews.com/${video.videoFile}`} target="_blank" rel="noopener noreferrer">
                    View File
                  </a>
                ) : (
                  'N/A'
                )}
              </td>
              <td>
                <button className="btn btn-warning" onClick={() => handleEdit(video)}>
                  Edit
                </button>
                <button
                  className="btn btn-danger ms-2"
                  onClick={() => showDeleteConfirmation(video)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {editingVideo && (
        <div>
          <h3>Edit Video</h3>
          <form onSubmit={() => handleUpdate(editingVideo)}>
          {editingVideo && (
  <div>
    <h3>Edit Video</h3>
    <form onSubmit={(e) => handleUpdate(e, editingVideo)}>
      <div className="mb-3">
        <label htmlFor="title" className="form-label">
          Title:
        </label>
        <input
          type="text"
          className="form-control"
          id="title"
          value={editingVideo.title}
          onChange={(e) =>
            setEditingVideo({
              ...editingVideo,
              title: e.target.value,
            })
          }
        />
      </div>
      <div className="mb-3">
        <label htmlFor="videoType" className="form-label">
          Video Type:
        </label>
        <select
          className="form-select"
          id="videoType"
          value={editingVideo.videoType}
          onChange={(e) =>
            setEditingVideo({
              ...editingVideo,
              videoType: e.target.value,
            })
          }
        >
          <option value="local">Local</option>
          <option value="youtube">YouTube</option>
        </select>
      </div>
      {editingVideo.videoType === 'local' && (
        <div className="mb-3">
          <label htmlFor="localFile" className="form-label">
            Local File:
          </label>
          <input
            type="file"
            className="form-control"
            id="localFile"
            onChange={(e) =>
              setEditingVideo({
                ...editingVideo,
                localFile: e.target.files[0],
              })
            }
          />
        </div>
      )}
      {editingVideo.videoType === 'youtube' && (
        <div className="mb-3">
          <label htmlFor="youtubeLink" className="form-label">
            YouTube Link:
          </label>
          <input
            type="text"
            className="form-control"
            id="youtubeLink"
            value={editingVideo.youtubeLink}
            onChange={(e) =>
              setEditingVideo({
                ...editingVideo,
                youtubeLink: e.target.value,
              })
            }
          />
        </div>
      )}
      <button type="submit" className="btn btn-primary">
        Update Video
      </button>
    </form>
  </div>
)}

            <button type="submit" className="btn btn-primary">
              Update Video
            </button>
          </form>
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Confirm Delete</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setShowDeleteModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                <p>Are you sure you want to delete this video?</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowDeleteModal(false)}
                >
                  Cancel
                </button>
                <button type="button" className="btn btn-danger" onClick={handleDelete}>
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default VideoList;
