import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const Cricket = () => {
  const [team1, setTeam1] = useState('');
  const [team2, setTeam2] = useState('');
  const [scoreTeam1, setScoreTeam1] = useState('');
  const [scoreTeam2, setScoreTeam2] = useState('');
  const [matches, setMatches] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if all fields are filled
    if (team1 && team2 && scoreTeam1 !== '' && scoreTeam2 !== '') {
      const newMatch = {
        team1,
        team2,
        scoreTeam1,
        scoreTeam2,
      };

      setMatches([...matches, newMatch]);

      // Clear form fields
      setTeam1('');
      setTeam2('');
      setScoreTeam1('');
      setScoreTeam2('');
    } else {
      alert('Please fill in all fields.');
    }
  };

  return (
    <Container className="mt-3">
      <h2>Cricket Match Input</h2>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col>
                <Form.Group controlId="team1">
                  <Form.Label>Team 1</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Team 1"
                    value={team1}
                    onChange={(e) => setTeam1(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="team2">
                  <Form.Label>Team 2</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Team 2"
                    value={team2}
                    onChange={(e) => setTeam2(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Form.Group controlId="scoreTeam1">
                  <Form.Label>Score Team 1</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Score Team 1"
                    value={scoreTeam1}
                    onChange={(e) => setScoreTeam1(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="scoreTeam2">
                  <Form.Label>Score Team 2</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Score Team 2"
                    value={scoreTeam2}
                    onChange={(e) => setScoreTeam2(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Button variant="primary" type="submit">
              Submit Match
            </Button>
          </Form>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <h3>Submitted Matches</h3>
          <Table striped bordered>
            <thead>
              <tr>
                <th>Team 1</th>
                <th>Team 2</th>
                <th>Score Team 1</th>
                <th>Score Team 2</th>
              </tr>
            </thead>
            <tbody>
              {matches.map((match, index) => (
                <tr key={index}>
                  <td>{match.team1}</td>
                  <td>{match.team2}</td>
                  <td>{match.scoreTeam1}</td>
                  <td>{match.scoreTeam2}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default Cricket;
