import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import ReactQuill from 'react-quill'; // Removed unnecessary QuillToolbar import
import 'react-quill/dist/quill.snow.css';

const ArticleA = () => {
  const [formData, setFormData] = useState({
    headline: '',
    subheadline: '',
    content: '',
    selectedTags: [],
    selectedAuthors: [],
    photos: [],
    youtubeLink: '',
    selectedCategory: '',
  });

  const [categories, setCategories] = useState([]);
  const [suggestedAuthors, setSuggestedAuthors] = useState([]);
  const [suggestedTags, setSuggestedTags] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [categoriesResponse, authorsResponse, tagsResponse] = await Promise.all([
          axios.get('https://api.theeverestnews.com/api/categories'),
          axios.get('https://api.theeverestnews.com/api/authors'),
          axios.get('https://api.theeverestnews.com/api/tags'),
        ]);

        setCategories(categoriesResponse.data?.data || []);
        setSuggestedAuthors(authorsResponse.data?.authors || []);
        setSuggestedTags(tagsResponse.data?.data || []);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = async (e) => {
    const filesArray = Array.from(e.target.files);

    const filesPromiseArray = filesArray.map(async (file) => {
      return new Promise((resolve, reject) => {
        resolve({ file: file, label: file.name });
      });
    });

    try {
      const filesData = await Promise.all(filesPromiseArray);
      setFormData({
        ...formData,
        photos: filesData,
      });
    } catch (error) {
      console.error('Error reading files:', error);
    }
  };

  const handleAuthorInputChange = (inputValue) => {
    console.log('Author Input Value:', inputValue);
    // Implement logic to filter authors based on inputValue if needed
  };

  const handleTagInputChange = (inputValue) => {
    console.log('Tag Input Value:', inputValue);
    // Implement logic to filter tags based on inputValue if needed
  };

  const handleAuthorSelection = (selectedOptions) => {
    setFormData({
      ...formData,
      selectedAuthors: selectedOptions,
    });
  };

  const handleTagSelection = (selectedOptions) => {
    setFormData({
      ...formData,
      selectedTags: selectedOptions,
    });
  };

  const handleCategorySelection = (selectedOption) => {
    setFormData({
      ...formData,
      selectedCategory: selectedOption ? selectedOption.value : '',
    });
  };

  const handleRemovePhoto = (index) => {
    const updatedPhotos = [...formData.photos];
    updatedPhotos.splice(index, 1);
    setFormData({
      ...formData,
      photos: updatedPhotos,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formDataToSend = new FormData();

      formDataToSend.append('headline', formData.headline);
      formDataToSend.append('subheadline', formData.subheadline);
      formDataToSend.append('content', formData.content);
      formDataToSend.append('youtubeLink', formData.youtubeLink);
      formDataToSend.append('category', formData.selectedCategory);

      formData.selectedTags.forEach((tag) => {
        formDataToSend.append('selectedTags', tag.value);
      });

      formData.selectedAuthors.forEach((author) => {
        formDataToSend.append('selectedAuthors', author.value);
      });

      formData.photos.forEach((photo, index) => {
        formDataToSend.append(`photos`, photo.file);
      });

      const response = await axios.post('https://api.theeverestnews.com/api/articles/create', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log(response.data);

      setFormData({
        headline: '',
        subheadline: '',
        content: '',
        selectedTags: [],
        selectedAuthors: [],
        photos: [],
        youtubeLink: '',
        selectedCategory: '',
      });
    } catch (error) {
      console.error('Error creating article:', error);
    }
  };

  return (
    <Container className="mt-5">
      <Form onSubmit={handleSubmit}>
        <h2 className="text-center mb-4 fs-2">Create Article</h2>

        <Row className="mb-3">
          <Col>
            <Form.Label className="h5">Headline</Form.Label>
            <Form.Control
              type="text"
              name="headline"
              onChange={handleInputChange}
              value={formData.headline}
              placeholder="Your headline here..."
              required
              className="fs-6"
            />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Label className="h5">Subheadline</Form.Label>
            <Form.Control
              type="text"
              name="subheadline"
              onChange={handleInputChange}
              value={formData.subheadline}
              placeholder="Your subheadline here..."
              className="fs-6"
            />
          </Col>
        </Row>

        <Form.Group controlId="formContent">
        <Form.Label>Content</Form.Label>
<ReactQuill
  theme="snow"
  value={formData.content}
  onChange={(value) => setFormData({ ...formData, content: value })}
  modules={{
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'color': [] }],
      ['link'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [
        { 'align': 'left' },
        { 'align': 'center' },
        { 'align': 'right' },
        { 'align': 'justify' }
      ],
      [{ 'indent': '-1'}, { 'indent': '+1' }],
      ['image'],
      ['video'],
      [{ 'line-height': [] }],
      [{ 'script': 'sub'}, { 'script': 'super' }],
      [{ 'list': 'check' }],
      ['clean'],
    ],
  }}
  placeholder="write the articles here "
  required
/>

</Form.Group>

        
        <Form.Group className="mb-3">
          <Form.Label className="h5">Choose Authors</Form.Label>
          <Select
            isMulti
            options={suggestedAuthors.map((author) => ({
              value: author._id,
              label: `${author.firstName} ${author.lastName}`,
            }))}
            onInputChange={handleAuthorInputChange}
            onChange={handleAuthorSelection}
            value={formData.selectedAuthors}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label className="h5">Choose Tags</Form.Label>
          <Select
            isMulti
            options={suggestedTags.map((tag) => ({ value: tag._id, label: tag.name }))}
            onInputChange={handleTagInputChange}
            onChange={handleTagSelection}
            value={formData.selectedTags}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label className="h5">Photos</Form.Label>
          <Form.Control type="file" name="photos" onChange={handleFileChange} multiple />
          {formData.photos.length > 0 && (
            <div className="mt-2">
              <strong className="h5">Selected Photos:</strong>
              <Row xs={1} md={2} lg={3} className="g-4 mt-2">
                {formData.photos.map((photo, index) => (
                  <Col key={index} className="mb-3">
                    <div className="position-relative">
                      <img
                        src={URL.createObjectURL(photo.file)}
                        alt={photo.label}
                        className="img-fluid rounded"
                      />
                      <Button
                        variant="danger"
                        size="sm"
                        className="position-absolute top-0 end-0"
                        onClick={() => handleRemovePhoto(index)}
                      >
                        Remove
                      </Button>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          )}
        </Form.Group>

        <Row className="mb-3">
          <Col>
            <Form.Label className="h5">YouTube Link</Form.Label>
            <Form.Control
              type="text"
              name="youtubeLink"
              onChange={handleInputChange}
              value={formData.youtubeLink}
              placeholder="Your YouTube link here..."
              className="fs-6"
            />
          </Col>
        </Row>

        <Form.Group className="mb-3">
          <Form.Label className="h5">Select Category</Form.Label>
          <Select
            options={categories.map((category) => ({ value: category._id, label: category.name }))}
            onChange={handleCategorySelection}
            value={
              formData.selectedCategory
                ? { value: formData.selectedCategory, label: categories.find((cat) => cat._id === formData.selectedCategory)?.name || formData.selectedCategory }
                : null
            }
          />
        </Form.Group>

        <div className="text-center">
          <Button type="submit" variant="primary" className="fs-5">
            Create Article
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default ArticleA;
