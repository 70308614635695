import React, { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const VideoLinks = () => {
  const [videoType, setVideoType] = useState('local');
  const [videoInfo, setVideoInfo] = useState({
    title: '',
    localFile: null,
    youtubeLink: '',
  });

  const handleVideoTypeChange = (event) => {
    setVideoType(event.target.value);
  };

  const handleInputChange = (event) => {
    const { name, value, files } = event.target;

    setVideoInfo((prevInfo) => ({
      ...prevInfo,
      [name]: name === 'localFile' ? files[0] : value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const formData = new FormData();
      formData.append('title', videoInfo.title);
      formData.append('videoType', videoType);

      if (videoType === 'local') {
        formData.append('localFile', videoInfo.localFile);
      } else {
        formData.append('youtubeLink', videoInfo.youtubeLink);
      }

      const response = await axios.post('https://api.theeverestnews.com/api/videos/create', formData);

      // Handle the response as needed
      toast.success(response.data.message);
    } catch (error) {
      console.error('Error creating video:', error);
      toast.error('Failed to create video');
    }
  };
  return (
    <div className="container mt-5">
      <h2 className="mb-4">Create Video</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="videoType" className="form-label">
            Video Type:
          </label>
          <select
            id="videoType"
            className="form-select"
            value={videoType}
            onChange={handleVideoTypeChange}
          >
            <option value="local">Local</option>
            <option value="youtube">YouTube</option>
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="title" className="form-label">
            Title:
          </label>
          <input
            type="text"
            id="title"
            className="form-control"
            name="title"
            value={videoInfo.title}
            onChange={handleInputChange}
            required
          />
        </div>
        {videoType === 'local' && (
          <div className="mb-3">
            <label htmlFor="localFile" className="form-label">
              Local File:
            </label>
            <input
              type="file"
              id="localFile"
              className="form-control"
              name="localFile"
              onChange={handleInputChange}
              accept="video/*"
            />
          </div>
        )}
        {videoType === 'youtube' && (
          <div className="mb-3">
            <label htmlFor="youtubeLink" className="form-label">
              YouTube Link:
            </label>
            <input
              type="text"
              id="youtubeLink"
              className="form-control"
              name="youtubeLink"
              value={videoInfo.youtubeLink}
              onChange={handleInputChange}
              required
            />
          </div>
        )}
        <div className="mb-3">
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default VideoLinks;
