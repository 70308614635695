import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './analytics.css';

const convertToNepaliNumber = (number) => {
  const nepaliNumbers = ['०', '१', '२', '३', '४', '५', '६', '७', '८', '९'];
  return number.toString().split('').map(digit => nepaliNumbers[digit]).join('');
};

const Analytics = () => {
  const [articles, setArticles] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [headlineSearch, setHeadlineSearch] = useState('');
  const [sortBy, setSortBy] = useState('lastUpdated'); // Default sorting by last updated
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 10;

  useEffect(() => {
    // Fetch all articles
    axios.get(`https://api.theeverestnews.com/api/articles/all`)
      .then(response => setArticles(response.data.data))
      .catch(error => console.error('Error fetching articles:', error));
  }, []);

  const handleHeadlineClick = async (articleId) => {
    try {
      // Fetch detailed data for the selected article
      const response = await axios.get(`https://api.theeverestnews.com/api/articles/byId/${articleId}`);
      const articleDetails = response.data.data;

      // Update the state with the selected article details
      setSelectedArticle(articleDetails);
    } catch (error) {
      console.error('Error fetching detailed article data:', error);
    }
  };

  const handleClosePopup = () => {
    // Close the popup
    setSelectedArticle(null);
  };

  // Apply sorting based on the selected option
  const sortedArticles = articles.sort((a, b) => {
    switch (sortBy) {
      case 'lastUpdated':
        return new Date(b.lastUpdated) - new Date(a.lastUpdated);
      case 'topViews':
        return b.views - a.views;
      // Add more cases for additional sorting options as needed
      default:
        return 0; // Default case: no sorting
    }
  });

  // Implement pagination
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = sortedArticles.slice(indexOfFirstArticle, indexOfLastArticle);

  const filteredArticles = currentArticles.filter(article =>
    !headlineSearch || article.headline.toLowerCase().includes(headlineSearch.toLowerCase())
  );

  // Calculate page numbers
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(sortedArticles.length / articlesPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div>
      <h1>Analytics Dashboard</h1>

      <div className="search-bar">
        <input
          type="text"
          placeholder="Search by headline"
          value={headlineSearch}
          onChange={(e) => setHeadlineSearch(e.target.value)}
        />
        <select onChange={(e) => setSortBy(e.target.value)} value={sortBy}>
          <option value="lastUpdated">Last Updated</option>
          <option value="topViews">Top Views</option>
          {/* Add more sorting options as needed */}
        </select>
      </div>

      <h2>Articles:</h2>
      {filteredArticles.map((article, index) => (
        <div key={article._id}>
          <p>{convertToNepaliNumber(indexOfFirstArticle + index + 1)}. </p>
          <h3
            style={{ cursor: 'pointer' }}
            onClick={() => handleHeadlineClick(article._id)}
          >
            {article.headline}
          </h3>
        </div>
      ))}

      {/* Pagination buttons */}
      <div className="pagination">
        {pageNumbers.map(number => (
          <button key={number} onClick={() => setCurrentPage(number)}>
            {convertToNepaliNumber(number)}
          </button>
        ))}
      </div>

      {selectedArticle && (
        <div className="popup">
          <div className="popup-inner analytics-popup">
            <h2>{selectedArticle.headline}</h2>
            <div className="article-info">
              <p className="views-info">Views: {selectedArticle.views}</p>
              <p className="share-count-info">Share Count: {selectedArticle.shareCount}</p>
            </div>
            <button onClick={handleClosePopup}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Analytics;
