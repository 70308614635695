import React, { useState, useEffect } from 'react';
import { Container, Table, Row, Col, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const MonthCalendar = () => {
  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const currentDay = currentDate.getDate();

  // State for slider values
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const daysInSelectedMonth = new Date(selectedYear, selectedMonth + 1, 0).getDate();
  const firstDayOfSelectedMonth = new Date(selectedYear, selectedMonth, 1).getDay();

  const getDaysArray = () => {
    const daysArray = [];

    for (let i = 1; i <= daysInSelectedMonth; i++) {
      daysArray.push(i);
    }

    return daysArray;
  };

  const renderCalendarHeader = () => (
    <thead>
      <tr>
        <th colSpan="7" className="calendar-header">
          {months[selectedMonth]} {selectedYear}
        </th>
      </tr>
      <tr>
        {daysOfWeek.map((day) => (
          <th key={day}>{day}</th>
        ))}
      </tr>
    </thead>
  );

  const renderCalendarBody = () => {
    const daysArray = getDaysArray();
    const weeksArray = [];

    for (let i = 0; i < daysInSelectedMonth; i += 7) {
      const week = Array(7).fill(0).map((_, dayIndex) => i + dayIndex + 1 - firstDayOfSelectedMonth);
      weeksArray.push(week);
    }

    return (
      <tbody>
        {weeksArray.map((week, index) => (
          <tr key={index}>
            {week.map((day) => {
              const isToday = day === currentDay && selectedMonth === currentMonth && selectedYear === currentYear;

              return (
                <td
                  key={day}
                  style={{
                    fontWeight: isToday ? 'bold' : 'normal',
                    color: isToday ? 'red' : 'inherit',
                    fontSize: isToday ? '1.6em' : '1em', // Adjust the font size
                  }}
                >
                  {day <= 0 ? '' : day}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    );
  };

  // Digital Clock State
  const [currentTime, setCurrentTime] = useState('');

  // Update the digital clock every 100 milliseconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      setCurrentTime(now.toLocaleTimeString());
    }, 100);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const handleMonthChange = (e) => {
    setSelectedMonth(parseInt(e.target.value, 10));
  };

  const handleYearChange = (e) => {
    setSelectedYear(parseInt(e.target.value, 10));
  };

  return (
    <Container className="mt-3">
      <h2>Month Calendar</h2>

      <Row className="mb-3">
        <Col xs={6}>
          <Form.Label>Month:</Form.Label>
          <Form.Select value={selectedMonth} onChange={handleMonthChange}>
            {months.map((month, index) => (
              <option key={index} value={index}>
                {month}
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col xs={6}>
          <Form.Label>Year:</Form.Label>
          <Form.Select value={selectedYear} onChange={handleYearChange}>
            {Array.from({ length: 10 }, (_, i) => currentYear + i).map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>

      <Table bordered>
        {renderCalendarHeader()}
        {renderCalendarBody()}
      </Table>
      <div className="digital-clock">
        <h3>Digital Clock</h3>
        <p>{currentTime}</p>
      </div>
    </Container>
  );
};

export default MonthCalendar;
