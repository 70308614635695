import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'react-bootstrap';

const AdvertisementList = () => {
  const [advertisements, setAdvertisements] = useState([]);
  const [editingAdvertisement, setEditingAdvertisement] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteAdvertisementId, setDeleteAdvertisementId] = useState(null);

  useEffect(() => {
    fetchAdvertisements();
  }, []);

  const fetchAdvertisements = async () => {
    try {
      const response = await axios.get('https://api.theeverestnews.com/api/advertisements');
      setAdvertisements(response.data.advertisements);
    } catch (error) {
      console.error('Error fetching advertisements:', error);
      toast.error('Failed to fetch advertisements');
    }
  };

  const handleEdit = (advertisement) => {
    setEditingAdvertisement(advertisement);
  };

  const handleUpdate = async (updatedAdvertisement) => {
    try {
      const response = await axios.put(
        `https://api.theeverestnews.com/api/advertisements/${updatedAdvertisement._id}`,
        {
          websiteLink: updatedAdvertisement.websiteLink,
          position: updatedAdvertisement.position,
        }
      );
      setEditingAdvertisement(null);
      fetchAdvertisements();
      toast.success('Advertisement updated successfully');
    } catch (error) {
      console.error('Error updating advertisement:', error);
      toast.error('Failed to update advertisement');
    }
  };

  const handleDelete = (id) => {
    setDeleteAdvertisementId(id);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await axios.delete(`https://api.theeverestnews.com/api/advertisements/${deleteAdvertisementId}`);
      fetchAdvertisements();
      toast.success('Advertisement deleted successfully');
      setShowDeleteModal(false);
    } catch (error) {
      console.error('Error deleting advertisement:', error);
      toast.error('Failed to delete advertisement');
    }
  };

  return (
    <div>
      <h2>Advertisement List</h2>
      <table className="table">
        <thead>
          <tr>
            <th>Website Link</th>
            <th>Image</th>
            <th>Position</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {advertisements.map((advertisement) => (
            <tr key={advertisement._id}>
              <td>{advertisement.websiteLink}</td>
              <td>
                <img
                  src={`https://api.theeverestnews.com/${advertisement.imagePath}`}
                  alt="Advertisement"
                  style={{ maxWidth: '200px' }}
                />
              </td>
              <td>{advertisement.position}</td>
              <td>
                <button className="btn btn-warning" onClick={() => handleEdit(advertisement)}>
                  Edit
                </button>
                <button
                  className="btn btn-danger ms-2"
                  onClick={() => handleDelete(advertisement._id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {editingAdvertisement && (
        <div>
          <h3>Edit Advertisement</h3>
          <form onSubmit={() => handleUpdate(editingAdvertisement)}>
            <div className="mb-3">
              <label htmlFor="websiteLink" className="form-label">
                Website Link:
              </label>
              <input
                type="text"
                className="form-control"
                id="websiteLink"
                value={editingAdvertisement.websiteLink}
                onChange={(e) =>
                  setEditingAdvertisement({
                    ...editingAdvertisement,
                    websiteLink: e.target.value,
                  })
                }
              />
            </div>
            <div className="mb-3">
              <label htmlFor="position" className="form-label">
                Position:
              </label>
              <select
                className="form-control"
                id="position"
                value={editingAdvertisement.position}
                onChange={(e) =>
                  setEditingAdvertisement({
                    ...editingAdvertisement,
                    position: e.target.value,
                  })
                }
              >
                <option value="top">Top</option>
                <option value="middle">Middle</option>
                <option value="bottom">Bottom</option>
                <option value="sidebar">Sidebar</option>
                <option value="below_category">Below Category</option>
                <option value="Premium">Premium</option>
               <option value="Popup">Popup</option>
              </select>
            </div>
            <button type="submit" className="btn btn-primary">
              Update Advertisement
            </button>
          </form>
        </div>
      )}
      
      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this advertisement?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirm}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </div>
  );
};

export default AdvertisementList;
