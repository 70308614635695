import React from 'react';
import { Navbar, Nav, Form, FormControl, Button, Badge, Image, Dropdown } from 'react-bootstrap';
import { BsSearch, BsBell, BsEnvelope, BsGear, BsSun, BsPersonFill } from 'react-icons/bs';
import { FaFacebook } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import profileImage from './styles/logo.png';
import { useUser } from '../Context/UserContext';



const MyNavbar = () => {

  const { handleLogout } = useUser();

const handleLogoutClick = () => {
    handleLogout();
    // Additional logic after logout if needed
};

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Navbar.Brand href="/" className="brand-logo">
       The Everest News
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbar" />
      <Navbar.Collapse id="navbar">
        <Form className="d-flex">
          <FormControl type="text" placeholder="Search" className="mr-2" />
          <Button variant="outline-secondary">
            <BsSearch /> {/* Search Icon */}
          </Button>
        </Form>
        <Nav className="ml-auto align-items-center">
         
          <Nav.Item>
            <Nav.Link href="/notifications" className="nav-link">
              <span className="nav-link-text">Notifications</span>
              <BsBell /> {/* Bell Icon for Notifications */}
              <Badge pill variant="danger" className="notification-badge">
                3
              </Badge>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/messages" className="nav-link">
              <span className="nav-link-text">Messages</span>
              <BsEnvelope /> {/* Envelope Icon for Messages */}
              <Badge pill variant="info" className="notification-badge">
                5
              </Badge>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/settings" className="nav-link">
              <span className="nav-link-text">Settings</span>
              <BsGear /> {/* Gear Icon */}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/help" className="nav-link">
              <span className="nav-link-text">Help</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Button variant="outline-secondary" className="theme-switcher">
              <span className="nav-link-text">Change Theme</span>
              <BsSun /> {/* Sun Icon for Light Theme */}
              
            </Button>
            <a href="https://www.facebook.com/profile.php?id=61557594452068" target="_blank" style={{ color: "#CCCCCC", fontSize: "20px", marginLeft: "21px" }}>
          <FaFacebook />
          facebook
           </a>


          </Nav.Item>
          <Nav.Item>
            <Dropdown align="end">
              <Dropdown.Toggle as={Nav.Link} id="user-dropdown" className="user-profile">
              <Image
             src={profileImage} // Using the imported image as the source
           roundedCircle
            className="profile-picture"
          alt="Profile"
          style={{ width: "2.5rem", height: "2.5rem" }} // Setting width and height to 4 rem
           />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="/profile">Profile</Dropdown.Item>
                <Dropdown.Item href="/account-settings">Account Settings</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={handleLogoutClick}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default MyNavbar;
