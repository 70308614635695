import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { BsSun, BsMoon, BsSunset, BsMoonStars } from 'react-icons/bs';
import axios from 'axios'; // Import Axios for making HTTP requests
import './styles/profile.scss';

const Profile = () => {
  const [greeting, setGreeting] = useState('');
  const [user, setUser] = useState({ name: '', gender: '' });

  const getTimeOfDay = () => {
    const currentHour = new Date().getHours();

    if (currentHour >= 5 && currentHour < 12) {
      return { message: 'Morning', icon: <BsSun style={{ ...iconStyle, color: 'orange' }} /> };
    } else if (currentHour >= 12 && currentHour < 17) {
      return { message: 'Afternoon', icon: <BsSunset style={{ ...iconStyle, color: '#FFD700' }} /> };
    } else if (currentHour >= 17 && currentHour < 20) {
      return { message: 'Evening', icon: <BsMoon style={{ ...iconStyle, color: 'orange' }} /> };
    } else {
      return { message: 'Night', icon: <BsMoonStars style={{ ...iconStyle, color: '#696969' }} /> };
    }
  };

  useEffect(() => {
    const { message } = getTimeOfDay();
    setGreeting(`Good ${message}!`);

    // Fetch user information (name and gender) from backend
    fetchUserInfo();
  }, []);
  const fetchUserInfo = async () => {
    try {
      const response = await axios.get('https://api.theeverestnews.com/api/auth/user-info', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
  
      if (response.status === 200) {
        const userData = response.data;
  
        setUser(userData);
      } else {
        console.error('Failed to fetch user information');
      }
    } catch (error) {
      console.error('Error fetching user information:', error);
    }
  };
  

  const iconStyle = {
    fontSize: '3rem',
    marginBottom: '1rem', 
  };

  const getTitle = () => {
    return user.gender === 'male' ? 'Mr.' : 'Miss.';
  };

  return (
    <Container className="mt-3">
      <Row className="justify-content-center align-items-center">
        <Col>
          <h3>{greeting}</h3>
          <h4>
            {getTitle()} <span className="user-name">{user.username}</span>
          </h4>
          {/* Display the time icon */}
          <div className="text-center">{getTimeOfDay().icon}</div>
          {/* Draw lines below the icon */}
          <div className="line-1"></div>
          <div className="line-2"></div>
        </Col>
      </Row>
    </Container>
  );
};

export default Profile;
