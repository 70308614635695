import React, { useState } from 'react';
import { Routes, Route,} from 'react-router-dom';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import Sidebar from "./Sidebar";
import AdminList from "./AdminList";
import Profile from "./Profile";
import Navbar from './Navbar';
import Author from '../components/Author';
import DashboardEnglish  from '../components/englishArticle'
import TagList from './TagList';




import Articles from './Articles';
import Analytics from './Analytics';
import Articlelist from './Newslist';
import EnglishArticleList from './englishArticlelist';
import Photo from './Photogrid';
import Photolist from './Photolist';
import Videos from './VideosLinks';
import Videolist from './Videolist';
import Changepassword from './User';
import Categories from './NewsCategory';
import Review from './Review';
import Cricket from './SportsLiveCricket';
import Football from './SportsLiveFootball';
import Geomap from './GeoMap';
import Calendar from './Calender';
import Schedule from './Schedule';
import Authorlist from './AuthorList';
import Advertisement from './Advertisement';
import Advertisementlist from './AdvertisementList';
import ActivityLog from './ActivityLog';
import Security from './Security';



const DashboardBody = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <>
      
       
     
            <Navbar />
                <div className="row">
                    <div className="col-lg-3">
                        <Sidebar />
                    </div>
                    <div className="col-lg-6">
                    <Profile />
            <Routes>
            <Route path='/author' element={<Author/>}/>
            <Route path='/english' element={<DashboardEnglish/>}/>
            <Route path='/tags' element={<TagList/>}/>
            <Route path='/articles' element={<Articles/>}/>
            <Route path='/analytics' element={<Analytics/>}/>
            <Route path='/articleslist' element={<Articlelist/>}/>
            <Route path='/englisharticleslist' element={<EnglishArticleList/>}/>
            <Route path='/photo' element={<Photo/>}/>
            <Route path='/photolist' element={<Photolist/>}/>
            <Route path='/videos' element={<Videos/>}/>
            <Route path='/videoslist' element={<Videolist/>}/>
            <Route path='/categories' element={<Categories />}/>
            <Route path='/review ' element={<Review />}/>
            <Route path='/cricket' element={<Cricket/>}/>
            <Route path='/football' element={<Football/>}/>
            <Route path='/geomap' element={<Geomap/>}/>
            <Route path='/calender' element={<Calendar/>}/>
            <Route path='/authorlist' element={< Authorlist/>}/>
            <Route path='/ads' element={< Advertisement/>}/>
            <Route path='/adslist' element={< Advertisementlist/>}/>
            <Route path='/activity' element={< ActivityLog/>}/>
            <Route path='/security' element={<  Security/>}/>
            <Route path='/schedule' element={< Schedule/>}/>




            
          </Routes>
                </div>
                    <div className="col-lg-3">
                        <div className="card">
                            <div className="card-header d-flex justify-content-between align-items-center" onClick={toggleCollapse}>
                                <span className="fw-bold">Admin List</span>
                                {isCollapsed ? <FaChevronDown /> : <FaChevronUp />}
                            </div>
                            <div className={`card-body ${isCollapsed ? 'd-none' : ''}`}>
                                <AdminList />
                            </div>
                            
                        </div>
                    </div>
                   
                </div>
              
        </>
    );
};

export default DashboardBody;
