import React from 'react';
import { Nav, Dropdown } from 'react-bootstrap';
import {
  BsFillHouseDoorFill, BsPeople, BsImage, BsYoutube, BsTag, BsFileText,
  BsGraphUp, BsGear, BsFileEarmarkText, BsShieldLock,
  BsTools, BsBoxArrowRight, BsMap
} from 'react-icons/bs';
import { RiAdvertisementLine} from 'react-icons/ri';
import { BiCategory, BiSolidWatch } from 'react-icons/bi';
import { MdOutlineTv } from 'react-icons/md';
import './Sidebar.css'; // Add your custom CSS file

import 'bootstrap/dist/css/bootstrap.min.css';
import { useUser } from '../Context/UserContext';

const Sidebar = () => {
  const { handleLogout } = useUser();

    const handleLogoutClick = () => {
        handleLogout();
        // Additional logic after logout if needed
    };
  return (
    <Nav defaultActiveKey="/" className="flex-column sidebar bg-dark text-white">
      <Nav.Item>
        <Nav.Link href="/">
          <BsFillHouseDoorFill className="icon" /> Dashboard
        </Nav.Link>
      </Nav.Item>
      <Dropdown as={Nav.Item}>
        <Dropdown.Toggle as={Nav.Link} variant="link">
          <BsPeople className="icon" /> Author Management
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item href="/author">Add Authors</Dropdown.Item>
          <Dropdown.Item href="/authorlist">List of Authors</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown as={Nav.Item}>
        <Dropdown.Toggle as={Nav.Link} variant="link">
          <BsFileText className="icon" /> News Management
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item href="/articles">Post News</Dropdown.Item>
          <Dropdown.Item href="/articleslist">Update/Delete News List</Dropdown.Item>
          <Dropdown.Item href="/english">English post News</Dropdown.Item>
          <Dropdown.Item href="/englisharticleslist">Update/Delete English</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown as={Nav.Item}>
        <Dropdown.Toggle as={Nav.Link} variant="link">
          <BsImage className="icon" /> Photo Management
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item href="/photo">Post Photo</Dropdown.Item>
          <Dropdown.Item href="/photolist">Update/Delete Photo</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown as={Nav.Item}>
        <Dropdown.Toggle as={Nav.Link} variant="link">
          <BsYoutube className="icon" /> Video Management
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item href="/videos">Post Video</Dropdown.Item>
          <Dropdown.Item href="/videoslist">Update/Delete Video</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown as={Nav.Item}>
        <Dropdown.Toggle as={Nav.Link} variant="link">
          <RiAdvertisementLine className="icon" /> Ads Management
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item href="/ads">Create Advertisement</Dropdown.Item>
          <Dropdown.Item href="/adslist">Update/Delete Ads</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Nav.Item>
        <Nav.Link href="/schedule">
          <BiSolidWatch className="icon" /> Schedule
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href="/categories">
          <BiCategory className="icon" /> Category Management
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href="/tags">
          <BsTag className="icon" /> Tag Management
        </Nav.Link>
      </Nav.Item>
      <Dropdown as={Nav.Item}>
        <Dropdown.Toggle as={Nav.Link} variant="link">
          <MdOutlineTv className="icon" /> Live Score
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item href="/football">Football</Dropdown.Item>
          <Dropdown.Item href="/cricket">Cricket</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Nav.Item>
        <Nav.Link href="/analytics">
          <BsGraphUp className="icon" /> Analytics
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href="/geomap">
          <BsMap className="icon" /> GeoMap
        </Nav.Link>
      </Nav.Item>
      <Dropdown as={Nav.Item}>
        <Dropdown.Toggle as={Nav.Link} variant="link">
          <BsGear className="icon" /> Settings
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item href="/changepassword">Change Password</Dropdown.Item>
          <Dropdown.Item href="/changeemail">Change Email</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown as={Nav.Item}>
        <Dropdown.Toggle as={Nav.Link} variant="link">
          <BsFileEarmarkText className="icon" /> Reports
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item href="/user-reports">User Reports</Dropdown.Item>
          <Dropdown.Item href="/performance-reports">Performance Reports</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown as={Nav.Item}>
        <Dropdown.Toggle as={Nav.Link} variant="link">
          <BsShieldLock className="icon" /> Security
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item href="/activity">Activity logs</Dropdown.Item>
          <Dropdown.Item href="/security">Security Logs</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Nav.Item>
        <Nav.Link href="/calender">
          <BsTools className="icon" /> Calender
        </Nav.Link>
      </Nav.Item>

      <Nav.Item>
        {/* Render logout button using BsBoxArrowRight icon */}
        <button className="icon-btn" onClick={handleLogoutClick}>
          <BsBoxArrowRight className="icon" /> Logout
        </button>
      </Nav.Item>
    </Nav>
  );
};

export default Sidebar;
