// Category.js
import React, { useState, useEffect } from 'react';
import { Container, Card, Button, Modal, Form } from 'react-bootstrap';
import axios from 'axios';

const Category = () => {
  const [categories, setCategories] = useState([]);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false); // Add this line
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [updateFormData, setUpdateFormData] = useState('');
  const [addFormData, setAddFormData] = useState('');

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get('https://api.theeverestnews.com/api/categories');
      setCategories(response.data.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const handleUpdate = (category) => {
    setSelectedCategory(category._id);
    setUpdateFormData(category.name);
    setShowUpdateModal(true);
  };

  const handleUpdateSubmit = async () => {
    try {
      await axios.put(`https://api.theeverestnews.com/api/categories/${selectedCategory}`, {
        name: updateFormData,
      });
      fetchCategories();
      setShowUpdateModal(false);
    } catch (error) {
      console.error('Error updating category:', error);
    }
  };

  const handleAddSubmit = async () => {
    try {
      await axios.post('https://api.theeverestnews.com/api/categories', { name: addFormData });
      fetchCategories();
      setShowAddModal(false);
      setAddFormData('');
    } catch (error) {
      console.error('Error adding category:', error);
    }
  };

  const handleDelete = (category) => {
    setSelectedCategory(category._id);
    setShowDeleteModal(true);
  };

  const handleDeleteSubmit = async () => {
    try {
      await axios.delete(`https://api.theeverestnews.com/api/categories/${selectedCategory}`);
      fetchCategories();
      setShowDeleteModal(false);
    } catch (error) {
      console.error('Error deleting category:', error);
    }
  };

  return (
    <Container className="category-container mt-3">
      <h2>Categories</h2>
      {categories.map((category) => (
        <Card key={category._id} className="mb-3">
          <Card.Body>
            <Card.Title>{category.name}</Card.Title>
            <Button variant="info" className="mr-2" onClick={() => handleUpdate(category)}>
              Update
            </Button>
            <Button variant="danger" onClick={() => handleDelete(category)}>
              Delete
            </Button>
          </Card.Body>
        </Card>
      ))}

      {/* Update Category Modal */}
      <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="updateCategory">
              <Form.Label>New Category Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter new category name"
                value={updateFormData}
                onChange={(e) => setUpdateFormData(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowUpdateModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleUpdateSubmit}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Add Category Modal */}
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="addCategory">
              <Form.Label>New Category Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter new category name"
                value={addFormData}
                onChange={(e) => setAddFormData(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAddModal(false)}>
            Cancel
          </Button>
          <Button variant="success" onClick={handleAddSubmit}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Category Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete the category?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>

          {/* to delete the category */}
          {/* <Button variant="danger" onClick={handleDeleteSubmit}>
            Delete
          </Button> */}
       
       
        </Modal.Footer>
      </Modal>

      {/* Button to Add Category */}
      <Button variant="success" onClick={() => setShowAddModal(true)}>
        Add Category
      </Button>
    </Container>
  );
};

export default Category;
