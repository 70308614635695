import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';

const NewsList = () => {
  const [articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [tags, setTags] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchCategory, setSearchCategory] = useState('');
  const [searchAuthor, setSearchAuthor] = useState('');
  const [sortBy, setSortBy] = useState('date');
  const [sortOrder, setSortOrder] = useState('desc');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [articleToDelete, setArticleToDelete] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);

  // State variables for article update
  const [updateHeadline, setUpdateHeadline] = useState('');
  const [updateSubheadline, setUpdateSubheadline] = useState('');
  const [updateContent, setUpdateContent] = useState('');
  const [updateTags, setUpdateTags] = useState([]);
  const [updateAuthors, setUpdateAuthors] = useState([]);
  const [updateCategory, setUpdateCategory] = useState('');
  const [updatePhotos, setUpdatePhotos] = useState([]);
  const [articleToUpdate, setArticleToUpdate] = useState(null);
  const [showUpdateModal, setUpdateModal] = useState(false);
  const [updatePhotosPreview, setUpdatePhotosPreview] = useState([]);

  useEffect(() => {
    fetchArticles();
    fetchCategories();
    fetchAuthors();
    fetchTags();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [articles, searchTerm, searchCategory, searchAuthor, sortBy, sortOrder, page, pageSize]);

  const fetchArticles = async () => {
    try {
      const response = await axios.get('https://api.theeverestnews.com/api/articles/all');
      setArticles(response.data?.data || []);
    } catch (error) {
      console.error('Error fetching articles:', error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get('https://api.theeverestnews.com/api/categories');
      setCategories(response.data?.data || []);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchAuthors = async () => {
    try {
      const response = await axios.get('https://api.theeverestnews.com/api/authors');
      setAuthors(response.data?.data || []);
    } catch (error) {
      console.error('Error fetching authors:', error);
    }
  };

  const fetchTags = async () => {
    try {
      const response = await axios.get('https://api.theeverestnews.com/api/tags');
      setTags(response.data?.data || []);
    } catch (error) {
      console.error('Error fetching tags:', error);
    }
  };

  const applyFilters = () => {
    let filtered = [...articles];

    if (searchCategory) {
      filtered = filtered.filter((article) => article.category === searchCategory);
    }

    if (searchAuthor) {
      filtered = filtered.filter((article) =>
        article.selectedAuthors.some((author) => author._id === searchAuthor)
      );
    }

    if (searchTerm) {
      const searchTermLowerCase = searchTerm.toLowerCase();
      filtered = filtered.filter(
        (article) =>
          article.headline.toLowerCase().includes(searchTermLowerCase) ||
          article.subheadline.toLowerCase().includes(searchTermLowerCase) ||
          article.content.toLowerCase().includes(searchTermLowerCase)
      );
    }

    filtered.sort((a, b) => {
      switch (sortBy) {
        case 'date':
          return sortOrder === 'asc'
            ? new Date(a.createdDate) - new Date(b.createdDate)
            : new Date(b.createdDate) - new Date(a.createdDate);
        case 'category':
          return sortOrder === 'asc' ? a.category.localeCompare(b.category) : b.category.localeCompare(a.category);
        case 'author':
          return sortOrder === 'asc'
            ? a.selectedAuthors[0].firstName.localeCompare(b.selectedAuthors[0].firstName)
            : b.selectedAuthors[0].firstName.localeCompare(a.selectedAuthors[0].firstName);
        default:
          return 0;
      }
    });

    setTotalPages(Math.ceil(filtered.length / pageSize));
    filtered = filtered.slice((page - 1) * pageSize, page * pageSize);

    setFilteredArticles(filtered);
  };

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchCategoryChange = (e) => {
    setSearchCategory(e.target.value);
  };

  const handleSearchAuthorChange = (e) => {
    setSearchAuthor(e.target.value);
  };

  const handleSortByChange = (e) => {
    setSortBy(e.target.value);
  };

  const handleSortOrderChange = (e) => {
    setSortOrder(e.target.value);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(parseInt(e.target.value, 10));
  };

  const handleDeleteArticle = async () => {
    try {
      await axios.delete(`https://api.theeverestnews.com/api/articles/${articleToDelete._id}`);
      setArticleToDelete(null);
      setShowDeleteModal(false);
      fetchArticles();
    } catch (error) {
      console.error('Error deleting article:', error);
    }
  };

  const handleShowDeleteModal = (article) => {
    setArticleToDelete(article);
    setShowDeleteModal(true);
  };

  const handleHideDeleteModal = () => {
    setArticleToDelete(null);
    setShowDeleteModal(false);
  };

  const handleUpdatePhotosChange = (e) => {
    const files = e.target.files;
    setUpdatePhotos(files);
    const previews = Array.from(files).map((file) => URL.createObjectURL(file));
    setUpdatePhotosPreview(previews);
  };

  const handleUpdateArticle = async () => {
    try {
      const formData = new FormData();

      formData.append('headline', updateHeadline);
      formData.append('subheadline', updateSubheadline);
      formData.append('content', updateContent);

      for (let i = 0; i < updatePhotos.length; i++) {
        formData.append('photos', updatePhotos[i]);
      }

      await axios.put(`https://api.theeverestnews.com/api/articles/update/${articleToUpdate._id}`, formData);

      setUpdateHeadline('');
      setUpdateSubheadline('');
      setUpdateContent('');
      setUpdatePhotos([]);
      setUpdatePhotosPreview([]);
      setArticleToUpdate(null);
      setUpdateModal(false);

      fetchArticles();
    } catch (error) {
      console.error('Error updating article:', error);
    }
  };

  const handleShowUpdateModal = (article) => {
    setArticleToUpdate(article);
    setUpdateHeadline(article.headline);
    setUpdateSubheadline(article.subheadline || '');
    setUpdateContent(article.content);
    setUpdateModal(true);
  };

  return (
    <Container className="mt-5">
      <h2 className="text-center mb-4 fs-2">News List</h2>

      {/* Search and Filter Section */}
      <Form className="mb-3">
        <Row className="mb-3">
          <Col>
            <Form.Control
              type="text"
              placeholder="Search by headline, subheadline, content..."
              value={searchTerm}
              onChange={handleSearchTermChange}
            />
          </Col>
          <Col>
            <Form.Select value={searchCategory} onChange={handleSearchCategoryChange}>
              <option value="">Search by Category</option>
              {categories.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.name}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col>
            <Form.Select value={searchAuthor} onChange={handleSearchAuthorChange}>
              <option value="">Search by Author</option>
              {authors.map((author) => (
                <option key={author._id} value={author._id}>
                  {`${author.firstName} ${author.lastName}`}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col>
            <Button variant="secondary" onClick={() => setPage(1)}>
              Reset Page
            </Button>
          </Col>
        </Row>

        {/* Sorting Section */}
        <Row className="mb-3">
          <Col>
            <Form.Select value={sortBy} onChange={handleSortByChange}>
              <option value="date">Sort by Date</option>
              <option value="category">Sort by Category</option>
              <option value="author">Sort by Author</option>
            </Form.Select>
          </Col>
          <Col>
            <Form.Select value={sortOrder} onChange={handleSortOrderChange}>
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </Form.Select>
          </Col>
        </Row>
      </Form>

      {/* Render filtered articles */}
      {filteredArticles.map((article) => (
        <div key={article._id}>
          <p>{article.headline}</p>
          <Button variant="primary" onClick={() => handleShowUpdateModal(article)}>
            Edit
          </Button>
          <Button variant="danger" onClick={() => handleShowDeleteModal(article)}>
            Delete
          </Button>
          <hr />
        </div>
      ))}

      {/* Pagination Section */}
      <Row className="justify-content-center mt-3">
        <Col xs="auto">
          <Form.Select value={pageSize} onChange={handlePageSizeChange}>
            <option value="5">5 per page</option>
            <option value="10">10 per page</option>
            <option value="20">20 per page</option>
          </Form.Select>
        </Col>
        <Col xs="auto">
          <Button variant="secondary" onClick={() => handlePageChange(page - 1)} disabled={page === 1}>
            Previous Page
          </Button>
        </Col>
        <Col xs="auto" className="fw-bold">
          Page {page} of {totalPages}
        </Col>
        <Col xs="auto">
          <Button variant="secondary" onClick={() => handlePageChange(page + 1)} disabled={page === totalPages}>
            Next Page
          </Button>
        </Col>
      </Row>

      {/* Deletion Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={handleHideDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the article "{articleToDelete?.headline}"?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleHideDeleteModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteArticle}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Update Article Modal */}
      <Modal show={showUpdateModal} onHide={() => setUpdateModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Article</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="updateHeadline">
              <Form.Label>Headline</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter headline"
                value={updateHeadline}
                onChange={(e) => setUpdateHeadline(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="updateSubheadline">
              <Form.Label>Subheadline</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter subheadline"
                value={updateSubheadline}
                onChange={(e) => setUpdateSubheadline(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="updateContent">
              <Form.Label>Content</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter content"
                value={updateContent}
                onChange={(e) => setUpdateContent(e.target.value)}
              />
            </Form.Group>

            {/* File input for photos */}
            <Form.Group className="mb-3" controlId="updatePhotos">
              <Form.Label>Update Photos</Form.Label>
              <Form.Control type="file" multiple onChange={handleUpdatePhotosChange} />
              <div className="mt-2">
                {/* Display photo previews */}
                {updatePhotosPreview.map((preview, index) => (
                  <img key={index} src={preview} alt={`Preview ${index}`} style={{ width: '100px', marginRight: '5px' }} />
                ))}
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setUpdateModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleUpdateArticle}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default NewsList;
