import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ListGroup, Button, Modal, Form, InputGroup, FormControl } from 'react-bootstrap';

const PhotoList = () => {
  const [photos, setPhotos] = useState([]);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [updateFormData, setUpdateFormData] = useState({
    description: '',
  });
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchPhotos();
  }, []);
 
  const fetchPhotos = async () => {
    try {
      const response = await axios.get('https://api.theeverestnews.com/api/photos/');
      setPhotos(response.data.photos);
    } catch (error) {
      console.error('Error fetching photos:', error);
    }
  };

  const handleUpdate = (photo) => {
    setSelectedPhoto(photo);
    setUpdateFormData({
      description: photo.description,
    });
    setShowUpdateModal(true);
  };

  const handleDelete = (photo) => {
    setSelectedPhoto(photo);
    setShowDeleteModal(true);
  };

  const handleUpdateSubmit = async () => {
    try {
      await axios.put(`https://api.theeverestnews.com/api/photos/${selectedPhoto._id}`, {
        description: updateFormData.description,
      });

      fetchPhotos();
      setShowUpdateModal(false);
    } catch (error) {
      console.error('Error updating photo:', error);
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      await axios.delete(`https://api.theeverestnews.com/api/photos/${selectedPhoto._id}`);

      fetchPhotos();
      setShowDeleteModal(false);
    } catch (error) {
      console.error('Error deleting photo:', error);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  

  return (
    <>
      <div className="mb-3">
        <h2>Photo List</h2>
        <InputGroup className="mb-3">
          <FormControl
            placeholder="Search by description"
            aria-label="Search by description"
            aria-describedby="basic-addon2"
            value={searchTerm}
            onChange={handleSearch}
          />
        </InputGroup>
      </div>

      <ListGroup>
        {photos
          .filter((photo) => photo.description.toLowerCase().includes(searchTerm.toLowerCase()))
          .map((photo) => (
            <ListGroup.Item key={photo._id} className="d-flex justify-content-between align-items-center">
              <div>
              
                <img
                  src={`https://api.theeverestnews.com/${photo.imagePath}`}
                  alt={photo.description}
                  className="img-thumbnail"
                  style={{ maxWidth: '100px', maxHeight: '100px', marginRight: '10px' }}
                  onError={() => console.error('Error loading image:', photo.imagePath)}

                />
                <span>{photo.description}</span>
              </div>
              <div>
                <Button variant="primary" size="sm" onClick={() => handleUpdate(photo)}>
                  Update
                </Button>{' '}
                <Button variant="danger" size="sm" onClick={() => handleDelete(photo)}>
                  Delete
                </Button>
              </div>
            </ListGroup.Item>
          ))}
      </ListGroup>

      {/* Update Photo Modal */}
      <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Photo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter description"
                value={updateFormData.description}
                onChange={(e) => setUpdateFormData({ ...updateFormData, description: e.target.value })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowUpdateModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdateSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this photo?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirm}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PhotoList;
