import React from 'react';
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Import your components
import DashboardProfile from './Profile';
import DashboardArticles from './Articles';
import DashboardEnglish from './englishArticle';
import DashboardAnalytics from './Analytics';
import DashboardArticlelist from './Newslist';
import DashboardEnglishArticleList from './englishArticlelist';
import DashboardPhoto from './Photogrid';
import DashboardPhotolist from './Photolist';
import DashboardVideos from './VideosLinks';
import DashboardVideolist from './Videolist';
import DashboardChangepassword from './User';
import DashboardCategories from './NewsCategory';
import DashboardTags from './TagList';
import DashboardReview from './Review';
import DashboardSportCricket from './SportsLiveCricket';
import DashboardSportFootball from './SportsLiveFootball';
import DashboardGeomap from './GeoMap';
import DashboardCalendar from './Calender';
import DashboardSchedule from './Schedule';
import DashboardAuthor from './Author';
import DashboardAuthorlist from './AuthorList';
import DashboardAdvertisement from './Advertisement';
import DashboardAdvertisementlist from './AdvertisementList';
import DashboardActivityLog from './ActivityLog';
import DashboardSecurity from './Security';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import AdminList from './AdminList';


function DashboardManagement() {
  // Define an array of route objects
  const routes = [
    { key: 'dashboard-profile', path: 'profile', element: <DashboardProfile /> },
    { key: 'dashboard-articles', path: 'articles', element: <DashboardArticles /> },
    { key: 'dashboard-english', path: 'english', element: <DashboardEnglish /> },
    { key: 'dashboard-analytics', path: 'analytics', element: <DashboardAnalytics /> },
    { key: 'dashboard-analytics', path: 'articles/location/:id', element: <DashboardAnalytics /> },
    { key: 'dashboard-analytics', path: 'articles/:id', element: <DashboardAnalytics /> },
    { key: 'dashboard-articleslist', path: 'articleslist', element: <DashboardArticlelist /> },
    { key: 'dashboard-articleslist', path: 'englisharticleslist', element: <DashboardEnglishArticleList /> },
    { key: 'dashboard-photo', path: 'photo', element: <DashboardPhoto /> },
    { key: 'dashboard-photolist', path: 'photolist', element: <DashboardPhotolist /> },
    { key: 'dashboard-videos', path: 'videos', element: <DashboardVideos /> },
    { key: 'dashboard-videoslist', path: 'videoslist', element: <DashboardVideolist /> },
    { key: 'dashboard-changepassword', path: 'changepassword', element: <DashboardChangepassword /> },
    { key: 'dashboard-categories', path: 'categories', element: <DashboardCategories /> },
    { key: 'dashboard-tags', path: 'tags', element: <DashboardTags /> },
    { key: 'dashboard-review', path: 'review', element: <DashboardReview /> },
    { key: 'dashboard-sports-cricket', path: 'sports/cricket', element: <DashboardSportCricket /> },
    { key: 'dashboard-sports-football', path: 'sports/football', element: <DashboardSportFootball /> },
    { key: 'dashboard-geomap', path: 'geomap', element: <DashboardGeomap /> },
    { key: 'dashboard-calendar', path: 'calendar', element: <DashboardCalendar /> },
    { key: 'dashboard-articles-schedule', path: 'articles/schedule', element: <DashboardSchedule /> },
    { key: 'dashboard-author', path: 'author', element: <DashboardAuthor /> },
    { key: 'dashboard-authorlist', path: 'authorlist', element: <DashboardAuthorlist /> },
    { key: 'dashboard-ads', path: 'ads', element: <DashboardAdvertisement /> },
    { key: 'dashboard-adslist', path: 'adslist', element: <DashboardAdvertisementlist /> },
    { key: 'dashboard-activity', path: 'activity', element: <DashboardActivityLog /> },
    { key: 'dashboard-security', path: 'security', element: <DashboardSecurity /> },

  ];

  return (
    <>
    <Navbar/>
    <Sidebar></Sidebar>
    <AdminList/>
    <Router>
      <Routes>

      {/* {routes.map((route) => (
        <Route key={route.key} path={`/${route.path}/*`} element={route.element} />
      ))} */}
        <Route path='/author' element={<DashboardAuthor/>}/>
      </Routes>
      </Router>
        </>
  );
}

export default DashboardManagement;
