import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Advertisement = () => {
  const [websiteLink, setWebsiteLink] = useState('');
  const [photo, setPhoto] = useState(null);
  const [position, setPosition] = useState('');
  const [result, setResult] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setPhoto(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('image', photo);
    formData.append('websiteLink', websiteLink);
    formData.append('position', position);

    try {
      const response = await axios.post('https://api.theeverestnews.com/api/advertisements/create', formData);
      setResult(response.data.advertisement);
      toast.success('Advertisement created successfully');
    } catch (error) {
      console.error('Error creating advertisement:', error);
      toast.error('Failed to create advertisement');
    }
  };

  return (
    <div>
      <h2>Create Advertisement</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="websiteLink" className="form-label">
            Website Link:
          </label>
          <input
            type="text"
            className="form-control"
            id="websiteLink"
            value={websiteLink}
            onChange={(e) => setWebsiteLink(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="photo" className="form-label">
            Select Photo:
          </label>
          <input type="file" className="form-control" id="photo" onChange={handleFileChange} />
        </div>
        <div className="mb-3">
          <label htmlFor="position" className="form-label">
            Select Position:
          </label>
          <select
            className="form-control"
            id="position"
            value={position}
            onChange={(e) => setPosition(e.target.value)}
          >
            {/* Add your position options here */}
            <option value="">Select Position</option>
            <option value="top">Top</option>
            <option value="middle">Middle</option>
            <option value="bottom">Bottom</option>
            <option value="sidebar">Sidebar</option>
            <option value="below_category">Below Category</option>
            <option value="Premium">Premium</option>
            <option value="Popup">Popup</option>
          </select>
        </div>
        <button type="submit" className="btn btn-primary">
          Create Advertisement
        </button>
      </form>
      {result && (
        <div className="mt-3">
          <h3>Advertisement Created:</h3>
          <p>Website Link: {result.websiteLink}</p>
          <p>
            Image: <img src={result.imagePath} alt="Advertisement" style={{ maxWidth: '100px' }} />
          </p>
          <p>Position: {result.position}</p>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default Advertisement;
