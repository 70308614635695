import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '../../Context/UserContext';

const LoginForm = () => {
  const navigate=useNavigate();
  const { userData, setUserData } = useUser();
const[username, setUsername]=useState();
const [password,setPassword]=useState();

  const handleRoleCheck = async () => {
    try {      
      const token = localStorage.getItem('token');
      if (!token) {
        console.log('No token found');
        return;
      }
      const response = await fetch('https://api.theeverestnews.com/api/auth/user/role', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        if (data.role === 'admin') {
          const isAdmin = data.role === 'admin';
                    setUserData((prevUserData) => ({
            ...prevUserData,
            isAdmin,
          }));
        } else {
          console.log('User is not admin. Redirecting...');
          setTimeout(() => {
            alert('You are not authorized to access this resource. Redirecting to theeverestnews.com...');
            window.location.href = 'https://theeverestnews.com';
          }, 2000);
      
        }
      } else {
        const errorMessage = await response.text();
        console.error('Failed to fetch user role:', errorMessage);
      }
    } catch (error) {
      console.error('Role check error:', error);
    }
  };
  



  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://api.theeverestnews.com/api/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(
          {
            email:username,
            password:password
          }
        ),
      });
      if (response.ok) {
         navigate('/')              
        const data = await response.json();
        localStorage.setItem('token', data.token);
        console.log('Login successful. Received token:', data.token);
        handleRoleCheck();
        // onLogin(data.token);
      } else {
        const errorMessage = await response.text();
        console.error('Login failed:', errorMessage);
      }
    } catch (error) {
      console.error('Login error:', error);
    }

  };
console.log(userData.isAdmin)
  return (
    <Container>
    <Row className="justify-content-center">
      <Col md={6}>
        <div className="card p-4 mt-5">
          <h2 className="text-center mb-4">Welcome, {userData.username || 'Guest'}!</h2>
          <h2 className="text-center mb-4">Login</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Enter email"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="w-100">
              Sign In
            </Button>
          </Form>
          <div className="text-center mt-3">
            <p>Don't have an account? <Link to="/register">Register here</Link>.</p>
          </div>
        </div>
      </Col>
    </Row>
  </Container>
);
};

export default LoginForm;

