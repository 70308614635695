import React, { useState } from 'react';
import axios from 'axios';
import { Container, Form, Button, Col } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PhotoGrid = () => {
  const [description, setDescription] = useState('');
  const [imageFiles, setImageFiles] = useState([]);

  const handleFileChange = (e) => {
    setImageFiles(e.target.files);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleUpload = async () => {
    try {
      const formData = new FormData();
      formData.append('description', description);
      formData.append('uploadDate', new Date().toISOString()); // Add the current date
      for (let i = 0; i < imageFiles.length; i++) {
        formData.append('images', imageFiles[i]);
      }

      // Make sure to update the endpoint to your actual backend URL
      await axios.post('https://api.theeverestnews.com/photos/create', formData);

      // Show success notification
      toast.success('Photos uploaded successfully', {
        position: 'top-center',
        autoClose: 2000, // Notification will close after 2 seconds
      });

      // Clear description and selected files after successful upload
      setDescription('');
      setImageFiles([]);
    } catch (error) {
      console.error('Error uploading photos:', error);
      // Show error notification
      toast.error('Error uploading photos', {
        position: 'top-center',
        autoClose: 2000,
      });
    }
  };

  return (
    <Container className="mt-4">
      <h1>Upload Photos</h1>

      {/* Upload Form */}
      <Form>
        <Form.Group controlId="description">
          <Form.Label>Description</Form.Label>
          <Form.Control type="text" placeholder="Enter photo description" value={description} onChange={handleDescriptionChange} />
        </Form.Group>
        <Form.Group controlId="images">
          <Form.Label>Choose Images</Form.Label>
          <Form.Control type="file" multiple onChange={handleFileChange} />
        </Form.Group>
        <Col md={2} className="p-0">
          <Button variant="primary" onClick={handleUpload}>
            Upload
          </Button>
        </Col>
      </Form>

      {/* Toast container for notifications */}
      <ToastContainer />
    </Container>
  );
};

export default PhotoGrid;
