import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Button, Modal, Form } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const EnglishArticleList = () => {
  const [articles, setArticles] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [articleToDelete, setArticleToDelete] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 6;

  useEffect(() => {
    // Fetch articles from the backend
    const fetchArticles = async () => {
      try {
        const response = await axios.get('https://api.theeverestnews.com/api/english/all');
        setArticles(response.data.data);
      } catch (error) {
        console.error('Error fetching articles:', error);
      }
    };

    fetchArticles();
  }, []);

  const handleDeleteClick = (articleId) => {
    setArticleToDelete(articleId);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    if (articleToDelete) {
      try {
        // Make a DELETE request to the backend
        const response = await axios.delete(`https://api.theeverestnews.com/api/english/${articleToDelete}`);

        // Handle the response (you can update the UI accordingly)
        console.log('Article deleted successfully:', response.data);

        // Update the article list
        const updatedArticles = articles.filter((article) => article._id !== articleToDelete);
        setArticles(updatedArticles);

        // Close the delete modal
        setShowDeleteModal(false);
        setArticleToDelete(null);
      } catch (error) {
        console.error('Error deleting article:', error);
        // Handle errors or show an error message to the user
      }
    }
  };

  const handleEditClick = (articleId) => {
    // Find the selected article
    const article = articles.find((article) => article._id === articleId);

    // Set the selected article and open the update modal
    setSelectedArticle(article);
    setShowUpdateModal(true);
  };
  

  const prepareFormData = (article) => {
    const formData = new FormData();
  
    formData.append('headline', article.headline);
    formData.append('content', article.content);
    formData.append('tags', article.tags.join(','));
    formData.append('youtubeLink', article.youtubeLink);
    formData.append('category', article.category);
  
    if (article.photos) {
      formData.append('photos', article.photos);
    }
  
    return formData;
  };
  const handleUpdate = async () => {
    if (selectedArticle) {
      try {
        // Create FormData object
        const formData = new FormData();
        
        if (selectedArticle.photos && selectedArticle.photos.length > 0) {
          selectedArticle.photos.forEach((photo, index) => {
            formData.append(`photos[${index}]`, photo);
          });
        }
        
        
        // Append article details
        formData.append('headline', selectedArticle.headline);
        formData.append('content', selectedArticle.content);
        formData.append('tags', selectedArticle.tags.join(','));
        formData.append('youtubeLink', selectedArticle.youtubeLink);
        formData.append('category', selectedArticle.category);
  
        // Check if a new photo is selected
        if (selectedArticle.photos !== null) {
          // Append the new photo to FormData
          formData.append('photos', selectedArticle.photos);
        }
  
        // Make an update request to the backend using FormData
        const response = await axios.put(
          `https://api.theeverestnews.com/api/english/update/${selectedArticle._id}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data', // Set the content type for FormData
            },
          }
        );
  
        // Handle the response (you can update the UI accordingly)
        console.log('Article updated successfully:', response.data);
  
        // Update the article list
        const updatedArticles = articles.map((article) =>
          article._id === selectedArticle._id ? response.data.data : article
        );
        setArticles(updatedArticles);
  
        // Close the update modal
        setShowUpdateModal(false);
        setSelectedArticle(null);
      } catch (error) {
        console.error('Error updating article:', error);
        // Handle errors or show an error message to the user
      }
    }
  };
  
  

  const renderArticles = () => {
    // Filter articles based on the search term
    const filteredArticles = articles.filter((article) =>
      article.headline.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Sort articles by the latest update date in descending order
    const sortedArticles = filteredArticles.sort((a, b) =>
      new Date(b.updatedAt) - new Date(a.updatedAt)
    );

    // Calculate the index of the first article for the current page
    const indexOfLastArticle = currentPage * articlesPerPage;
    const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;

    // Slice the articles array to display only the ones for the current page
    const currentArticles = sortedArticles.slice(indexOfFirstArticle, indexOfLastArticle);

    return currentArticles.map((article) => (
      <Card key={article._id} className="mb-1.5">
        <Card.Body>
          <Card.Title>{article.headline}</Card.Title>
          <Button variant="info" onClick={() => handleEditClick(article._id)}>
            Edit
          </Button>
          <Button variant="danger" onClick={() => handleDeleteClick(article._id)}>
            Delete
          </Button>
        </Card.Body>
      </Card>
    ));
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(articles.length / articlesPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div>
      <h2>English Article List</h2>

      <Form.Group controlId="formSearch">
        <Form.Label>Search by Headline</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter headline to search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Form.Group>

      {renderArticles()}

      <div>
        {pageNumbers.map((number) => (
          <Button key={number} onClick={() => paginate(number)}>
            {number}
          </Button>
        ))}
      </div>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this article?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirm}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Update Article</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedArticle && (
            <Form>
              <Form.Group controlId="formHeadline">
                <Form.Label>Headline</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedArticle.headline}
                  onChange={(e) =>
                    setSelectedArticle({ ...selectedArticle, headline: e.target.value })
                  }
                />
              </Form.Group>

              <Form.Group controlId="formContent">
                <Form.Label>Content</Form.Label>
                <ReactQuill
                  theme="snow"
                  value={selectedArticle.content}
                  onChange={(value) =>
                    setSelectedArticle({ ...selectedArticle, content: value })
                  }
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, false] }],
                      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                      [{ color: [] }],
                      ['link'],
                      [{ list: 'ordered' }, { list: 'bullet' }],
                      ['clean'],
                    ],
                  }}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formTags">
                <Form.Label>Tags</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedArticle.tags ? selectedArticle.tags.join(', ') : ''}
                  onChange={(e) =>
                    setSelectedArticle({
                      ...selectedArticle,
                      tags: e.target.value.split(',').map((tag) => tag.trim()),
                    })
                  }
                />
              </Form.Group>

              <Form.Group controlId="formYouTubeLink">
                <Form.Label>YouTube Link</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedArticle.youtubeLink}
                  onChange={(e) =>
                    setSelectedArticle({ ...selectedArticle, youtubeLink: e.target.value })
                  }
                />
              </Form.Group>

              <Form.Group controlId="formCategory">
                <Form.Label>Category</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedArticle.category}
                  onChange={(e) =>
                    setSelectedArticle({ ...selectedArticle, category: e.target.value })
                  }
                >
                  <option value="">Select Category</option>
                  <option value="politics">Politics</option>
                  <option value="sports">Sports</option>
                  <option value="economics">Economics</option>
                  <option value="lifestyle">Lifestyle</option>
                  <option value="travel">Travel</option>
                  <option value="international">International</option>
                  <option value="science">Science</option>
                  <option value="society">Society</option>
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="formImage">
                <Form.Label>Photo (Update from Local Device)</Form.Label>
                <Form.Control
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    setSelectedArticle({ ...selectedArticle, photos: file });
                  }}
                />
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowUpdateModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EnglishArticleList;
