import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const Author = () => {
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [authorPhoto, setAuthorPhoto] = useState(null);

  const handleFileChange = (e) => {
    setAuthorPhoto(e.target.files[0]);
  };

  const handleCreateAuthor = async () => {
    try {
      // Check for required fields
      if (!firstName || !lastName || !authorPhoto) {
        toast.error('First name, last name, and photo are required', { position: 'top-center' });
        return;
      }

      const formData = new FormData();
      formData.append('firstName', firstName);
      formData.append('middleName', middleName);
      formData.append('lastName', lastName);
      formData.append('authorPhoto', authorPhoto);

      // Make API call
      const response = await axios.post('https://api.theeverestnews.com/api/authors/create', formData);

      // Clear input fields
      setFirstName('');
      setMiddleName('');
      setLastName('');
      setAuthorPhoto(null);

      // Show success notification
      toast.success(response.data.message, { position: 'top-center' });
    } catch (error) {
      console.error('Error creating author:', error.response.data);
      toast.error(error.response.data.error || 'An error occurred while creating the author', { position: 'top-center' });
    }
  };

  return (
    <div className="container mt-5">
      <h2>Author Details</h2>

      <div className="mb-3">
        <label htmlFor="firstName" className="form-label">First Name:</label>
        <input type="text" className="form-control" id="firstName" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
      </div>

      <div className="mb-3">
        <label htmlFor="middleName" className="form-label">Middle Name:</label>
        <input type="text" className="form-control" id="middleName" value={middleName} onChange={(e) => setMiddleName(e.target.value)} />
      </div>

      <div className="mb-3">
        <label htmlFor="lastName" className="form-label">Last Name:</label>
        <input type="text" className="form-control" id="lastName" value={lastName} onChange={(e) => setLastName(e.target.value)} />
      </div>

      <div className="mb-3">
        <label htmlFor="authorPhoto" className="form-label">Author Photo:</label>
        <input type="file" className="form-control" id="authorPhoto" accept="image/*" onChange={handleFileChange} />
      </div>

      <button className="btn btn-primary" onClick={handleCreateAuthor}>Create Author</button>

      {/* Toast container for notifications */}
      <ToastContainer />
    </div>
  );
};

export default Author;
